import http from '../http-common';

class NewsDataService {
  getTrendingNews() {
    return http.get('news/trending');
  }

  getRecentNews() {
    return http.get('news/recent');
  }

  getAllByType(type) {
    return http.get(`news/${type}`);
  }

  getEntity(type, slug) {
    return http.get(`/news/${type}/${slug}`);
  }

  getCoverage(link) {
    return http.get(`/news/coverage/${link}`);
  }
}

export default new NewsDataService();
