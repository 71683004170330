import styled, { css } from 'styled-components';
import {
  AppBar as AppBarAlias,
  Drawer as DrawerAlias,
  Toolbar as ToolbarAlias,
  Typography,
} from '@material-ui/core';
import { Link as LinkAlias } from 'react-router-dom';

const drawerWidth = '250px';

const AppBar = styled(AppBarAlias)`
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

const Drawer = styled(DrawerAlias)`
  .MuiDrawer-paper {
    width: ${drawerWidth};
  }
`;

const Link = styled(LinkAlias)`
  &:hover {
    text-decoration: none;
  }
`;

const Main = styled.main`
  transition: margin ${(props) => props.theme.transitions.duration.leavingScreen}ms
    ${(props) => props.theme.transitions.easing.sharp};

  ${(props) =>
    props.drawerOpen &&
    css`
      transition: margin
        ${(props) => props.theme.transitions.duration.enteringScreen}ms
        ${(props) => props.theme.transitions.easing.easeOut};
      margin-left: ${drawerWidth};
    `}
`;

const SiteTitle = styled(Typography)`
  color: #fff;
  font-family: 'Syne Mono', monospace;
`;

const Toolbar = styled(ToolbarAlias)`
  min-height: 48px;
`;

export const Styled = {
  AppBar,
  Drawer,
  Link,
  Main,
  SiteTitle,
  Toolbar,
};
