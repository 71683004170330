import React, { useContext, useState, useEffect } from 'react';
import NewsDataService from 'services/news.service';
import { useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { LoadingContext, NewsGroup, UpcomingReleases } from 'components';
import { useLocation } from 'react-router-dom';

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const Home = () => {
  const location = useLocation();
  const { showLoading } = useContext(LoadingContext);
  const [news, setNews] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    showLoading(true);
    let title = 'sci-fi hype';
    document.title = title;
    scrollToTop();

    if (location.pathname === '/new') {
      return NewsDataService.getRecentNews()
        .then((response) => {
          title = 'Most Recent Stories | ' + title;
          document.title = title;
          setNews(response.data);
          showLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    NewsDataService.getTrendingNews()
      .then((response) => {
        setNews(response.data);
        showLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [location]);

  return (
    <>
      <Grid container spacing={isMobile ? 0 : 5}>
        <Grid item sm>
          {news.map((media, index) => (
            <NewsGroup isMobile={isMobile} media={media} key={index.toString()} />
          ))}
        </Grid>

        <Grid item xs={12} sm={3}>
          <UpcomingReleases />
        </Grid>
      </Grid>
    </>
  );
};

export { Home };
