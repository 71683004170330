import http from '../http-common';

class ContentDataService {
  getAll() {
    return http.get('/content');
  }

  getUpcoming() {
    return http.get('/content/upcoming');
  }
}

export default new ContentDataService();
