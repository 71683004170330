import React, { useState, useEffect } from 'react';
import ContentDataService from 'services/content.service';

import { useTheme } from '@material-ui/core/styles';
import { Box, Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';

import { Link } from 'react-router-dom';

const UpcomingReleases = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [content, setContent] = useState([]);

  const retrieveContent = () => {
    ContentDataService.getUpcoming()
      .then((response) => {
        setContent(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveContent();
  }, []);

  return (
    <>
      <Typography gutterBottom variant="h6" component="h2">
        Upcoming Releases
      </Typography>
      {!isMobile && (
        <Box mb={2}>
          <Divider />
        </Box>
      )}

      <Box mb={isMobile ? 1 : 0}>
        <Grid container>
          {content.slice(0, isMobile ? 4 : 3).map((x, index) => {
            const omdb = JSON.parse(x.omdbapi);

            return (
              <Grid item xs={isMobile ? 6 : 12} key={index.toString()}>
                <Box height="100%" pb={isMobile ? 0 : 1}>
                  <Link to={x.slug}>
                    <img
                      alt={omdb.Title}
                      src={omdb.Poster}
                      width="100%"
                      height="100%"
                    />
                  </Link>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      {content.slice(isMobile ? 4 : 3).map((x, index) => {
        const omdb = JSON.parse(x.omdbapi);

        return (
          <div key={index.toString()}>
            <Box px={1}>
              <Typography gutterBottom variant="h6" component="h2">
                <Link to={x.slug}>{omdb.Title}</Link>
              </Typography>
            </Box>
          </div>
        );
      })}
    </>
  );
};

export { UpcomingReleases };
