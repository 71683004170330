import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { LoadingScreen } from 'components';
import { NavLinks } from './nav-links';
import { Styled } from './main-layout.style';

export const MainLayout = ({ children, mobile }) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(!mobile);
  }, [mobile]);

  return (
    <>
      <Styled.AppBar position="fixed">
        <Styled.Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton>

          <Styled.Link to="/">
            <Styled.SiteTitle variant="h6" noWrap>
              scifihype
            </Styled.SiteTitle>
          </Styled.Link>
        </Styled.Toolbar>
      </Styled.AppBar>

      <Styled.Drawer variant="persistent" anchor="left" open={open}>
        <Box mb={1.5}>
          <Styled.Toolbar />
        </Box>

        <NavLinks />
      </Styled.Drawer>

      <Styled.Main drawerOpen={open}>
        <Box mb={1.5}>
          <Styled.Toolbar />
        </Box>
        <LoadingScreen>{children}</LoadingScreen>
      </Styled.Main>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  mobile: PropTypes.bool,
};
