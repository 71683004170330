import { createGlobalStyle } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';

export const GlobalStyle = createGlobalStyle`
a {
	color: #333;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}
`;

const defaultTheme = createMuiTheme();

export { defaultTheme };
