import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AnalyticsDataService from 'services/analytics.service';

export const Redirect = () => {
  const { token } = useParams();
  const link = atob(token);

  useEffect(() => {
    try {
      AnalyticsDataService.postLink({ link: link }).catch(() => {
        //
      });
    } catch {
      //
    } finally {
      window.location.href = link;
    }
  }, [link]);

  return <></>;
};
