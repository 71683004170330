import React, { useContext, useState, useEffect } from 'react';
import NewsDataService from 'services/news.service';
import { useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { LoadingContext, NewsGroup, UpcomingReleases } from 'components';

import { useParams } from 'react-router-dom';

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const Type = () => {
  const { showLoading } = useContext(LoadingContext);
  const { type } = useParams();
  const [news, setNews] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    showLoading(true);
    let title = 'sci-fi hype';
    document.title = title;
    scrollToTop();

    NewsDataService.getAllByType(type)
      .then((response) => {
        setNews(response.data);
        showLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [type]);

  return (
    <>
      <Grid container spacing={isMobile ? 0 : 5}>
        <Grid item sm>
          {news.map((media, index) => (
            <NewsGroup isMobile={isMobile} media={media} key={index.toString()} />
          ))}
        </Grid>

        <Grid item xs={12} sm={3}>
          <UpcomingReleases />
        </Grid>
      </Grid>
    </>
  );
};

export { Type };
