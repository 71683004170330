import React, { useContext, useState, useEffect } from 'react';
import NewsDataService from 'services/news.service';
import { useTheme } from '@material-ui/core/styles';
import { Box, Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { LoadingContext, NewsItem, UpcomingReleases } from 'components';

import { useParams } from 'react-router-dom';

import { Styled } from './entity.style';

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const Entity = () => {
  const { showLoading } = useContext(LoadingContext);
  const { slug, type } = useParams();
  const [news, setNews] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    showLoading(true);
    let title = 'sci-fi hype';
    document.title = title;
    scrollToTop();

    return NewsDataService.getEntity(type, slug)
      .then((response) => {
        if (response.data[0]?.title) {
          title = response.data[0]?.title + ' | ' + title;
        }
        document.title = title;

        setNews(response.data);
        showLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [slug]);

  return (
    <>
      <Grid container spacing={isMobile ? 0 : 5}>
        <Grid item sm>
          {news[0]?.title && (
            <Grid item xs={12}>
              <Box mb={2}>
                <Box mb={1}>
                  <Typography variant="h6">{news[0].title}</Typography>
                </Box>
                <Divider />
              </Box>
            </Grid>
          )}

          {news.map((media, index) => {
            return (
              <Box mb={5} key={index.toString()}>
                <Styled.Card>
                  <NewsItem x={media} isMobile={isMobile} />
                </Styled.Card>
              </Box>
            );
          })}
        </Grid>

        <Grid item xs={12} sm={3}>
          <UpcomingReleases />
        </Grid>
      </Grid>
    </>
  );
};

export { Entity };
