import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LazyLoad from 'react-lazyload';
import { Box, CardMedia, Divider, Grid, Typography } from '@material-ui/core';

import { Link } from 'react-router-dom';

import { Styled } from './news-item.style';

const getTitle = (metajson) => {
  const publisher = getPublisher(metajson);

  let title = metajson.og?.title;

  if (title.endsWith(publisher)) {
    title = title.substring(0, title.indexOf(publisher)).trim();
  }

  title = title.replace(/\|$|-$|—$/g, '');

  return title.trim();
};

const getPublisher = (metajson) => {
  let publisher;

  if (metajson.jsonld && metajson.jsonld.length) {
    for (let i = 0; i < metajson.jsonld.length; i++) {
      if (
        metajson.jsonld[i] &&
        metajson.jsonld[i]['@type'] === 'NewsMediaOrganization'
      ) {
        publisher = metajson.jsonld[i]['name'];
      }
    }
  }

  if (!publisher) {
    publisher = metajson.og?.site_name || metajson.jsonld[0]?.name;
  }

  if (publisher === 'YouTube') {
    publisher = metajson.jsonld[0].itemListElement[0].item?.name + ' via YouTube';
  }

  if (metajson.jsonld[0]?.url && metajson.jsonld[0]?.url.includes('.avclub.com/')) {
    publisher = 'The A.V. Club - ' + publisher;
  }

  if (publisher === 'https://www.rogerebert.com/') {
    publisher = 'Roger Ebert';
  }

  return publisher;
};

const NewsItem = ({ x, index, total, isMobile }) => {
  const metajson = JSON.parse(x.metajson);

  return (
    <React.Fragment key={x?.slug + index?.toString()}>
      <Grid container spacing={isMobile ? 1 : 3}>
        <Styled.NewsItem item xs>
          <Box pt={index === 0 ? 1 : 0} pl={1.5} pb={index === total - 1 ? 1 : 0}>
            <Typography variant={index === 0 ? 'h6' : 'body2'}>
              <Link
                to={'/articles/' + btoa(x.link)}
                target="_blank"
                rel="noreferrer"
              >
                {getTitle(metajson)}
              </Link>
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="caption" color="textSecondary">
                  {getPublisher(metajson)} &bull;{' '}
                  {moment(x.published).utc().fromNow()}
                </Typography>
              </Grid>

              {x.related > 0 && (
                <Grid item>
                  <Link to={'/coverage/' + btoa(x.link)}>
                    <Typography variant="caption" color="textSecondary">
                      +{x.related} more {x.related > 1 ? 'sites' : 'site'}
                    </Typography>
                  </Link>
                </Grid>
              )}
            </Grid>
          </Box>
        </Styled.NewsItem>

        <Styled.NewsItemImage item xs={12} sm={3}>
          {(!index || index === 0) && (
            <Link to={'/articles/' + btoa(x.link)} target="_blank" rel="noreferrer">
              <LazyLoad height={100} offset={200}>
                <CardMedia component="img" image={metajson.og?.image} height="100" />
              </LazyLoad>
            </Link>
          )}
        </Styled.NewsItemImage>
      </Grid>

      {index === 0 && isMobile && (
        <Box pt={2}>
          <Divider />
        </Box>
      )}
    </React.Fragment>
  );
};

NewsItem.propTypes = {
  x: PropTypes.object.isRequired,
  index: PropTypes.number,
  total: PropTypes.number,
  isMobile: PropTypes.bool.isRequired,
};

export { NewsItem };
