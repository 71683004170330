import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const NewsItem = styled(Grid)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    order: 2;
  }
`;

const NewsItemImage = styled(Grid)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    order: 1;
  } ;
`;

export const Styled = {
  NewsItem,
  NewsItemImage,
};
