import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { NewsItem } from 'components';

import { Link } from 'react-router-dom';

import { Styled } from './news-group.style';

const NewsGroup = ({ isMobile, media }) => {
  return (
    <Box mb={5}>
      <Box p={2} pl={0}>
        <Typography variant="subtitle1">
          <Link to={media.type + '/' + media.slug}>{media.title}</Link>
        </Typography>
      </Box>

      <Styled.Card variant="outlined">
        {media.news.map((x, index) => (
          <NewsItem
            x={x}
            index={index}
            total={media.news.length}
            isMobile={isMobile}
            key={index.toString()}
          />
        ))}
      </Styled.Card>
    </Box>
  );
};

NewsGroup.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  media: PropTypes.object.isRequired,
};

export { NewsGroup };
