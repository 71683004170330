import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';

const links = [
  {
    to: '/',
    title: 'Trending Stories',
  },
  {
    to: '/new',
    title: 'Most Recent',
  },
];

const NavLinks = () => {
  return (
    <List>
      {links.map((link, index) => (
        <Link to={link.to} key={index.toString()}>
          <ListItem button>
            <ListItemText>{link.title}</ListItemText>
          </ListItem>
        </Link>
      ))}
    </List>
  );
};

export { NavLinks };
