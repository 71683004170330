import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress } from '@material-ui/core';

const LoadingContext = createContext();

const LoadingScreen = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoading = (showLoading) => {
    setLoading(showLoading);
  };

  return (
    <>
      {loading && (
        <Box mb={2}>
          <LinearProgress />
        </Box>
      )}

      <LoadingContext.Provider value={{ showLoading }}>
        {children}
      </LoadingContext.Provider>
    </>
  );
};

LoadingScreen.propTypes = {
  children: PropTypes.node.isRequired,
};

export { LoadingContext, LoadingScreen };
