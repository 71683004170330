import styled from 'styled-components';
import { Card as CardAlias } from '@material-ui/core';

const Card = styled(CardAlias)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    border-radius: 0;
  } ;
`;

export const Styled = {
  Card,
};
