import React from 'react';

import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';
import { defaultTheme, GlobalStyle } from 'themes';

import { useTheme } from '@material-ui/core/styles';
import { Container, CssBaseline, useMediaQuery } from '@material-ui/core';

import { MainLayout } from 'layouts';
import { Coverage, Home, Entity, Type } from 'pages';
import { Redirect } from 'components';

import { Route, Switch } from 'react-router-dom';

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <GlobalStyle />
        <Switch>
          <Route path="/articles/:token" component={Redirect} />
          <Route>
            <MainLayout mobile={isMobile}>
              <Container disableGutters={isMobile}>
                <Switch>
                  <Route path="/new" component={Home} />
                  <Route path="/coverage/:link" component={Coverage} />
                  <Route path="/:type/:slug" component={Entity} />
                  <Route path="/:type" component={Type} />
                  <Route path="/" component={Home} />
                </Switch>
              </Container>
            </MainLayout>
          </Route>
        </Switch>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
